import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Пропозиції | Тату салон Tattoo 044
			</title>
			<meta name={"description"} content={"Ваша уява, наші чорнила"} />
			<meta property={"og:title"} content={"Пропозиції | Тату салон Tattoo 044"} />
			<meta property={"og:description"} content={"Ваша уява, наші чорнила"} />
			<meta property={"og:image"} content={"https://luminiumrex.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://luminiumrex.com/images/03459182.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://luminiumrex.com/images/03459182.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://luminiumrex.com/images/03459182.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://luminiumrex.com/images/03459182.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://luminiumrex.com/images/03459182.png"} />
			<meta name={"msapplication-TileImage"} content={"https://luminiumrex.com/images/03459182.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Ознайомтеся з нашими послугами з татуювання
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				У тату-салоні Tattoo 044 ми віримо, що кожне татуювання - це глибоко особисте самовираження. Наші послуги розроблені таким чином, щоб задовольнити ваші унікальні смаки та вподобання, гарантуючи, що кожен витвір мистецтва, який ми створюємо, буде таким же індивідуальним, як і ви. Пориньте в можливості та дізнайтеся, як ми можемо втілити ваші мрії про татуювання в життя.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-red"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Записатися на візит
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://luminiumrex.com/images/6.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				padding="0px 10% 0px 0px"
				lg-width="100%"
				md-margin="0px 0px 30px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 25px 0px"
					color="--grey"
					font="normal 500 12px/14px --fontFamily-googleSourceSansPro"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
					text-transform="uppercase"
					letter-spacing="3px"
					md-margin="0px 0px 0px 0px"
				>
					Ваша уява, наші чорнила
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
					lg-margin="0px 0px 48px 0px"
					sm-margin="0px 0px 24px 0px"
				>
					Розкриваємо наш досвід
				</Text>
			</Box>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="flex-start"
				padding="0px 0px 32px 16px"
				lg-width="100%"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="flex-start"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					width="80%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					border-radius="24px"
					margin="0px 0px 34px 0px"
					lg-width="50%"
					lg-margin="0px 32px 34px 0px"
					lg-padding="0px 0px 75% 0px"
					sm-margin="0px 0px 24px 0px"
					sm-width="100%"
					sm-padding="0px 0px 135% 0px"
				>
					<Image
						src="https://luminiumrex.com/images/7.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top={0}
						left={0}
						right={0}
						bottom={0}
						min-height="100%"
					/>
				</Box>
				<Box lg-width="40%" sm-width="100%">
					<Text
						margin="0px 0px 12px 0px"
						color="--dark"
						font="--headline3"
						lg-text-align="center"
						display="flex"
						align-items="flex-start"
					>
						Індивідуальний дизайн татуювання
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="left"
						display="flex"
						align-items="flex-start"
					>
						Мистецтво на замовлення: Індивідуальний дизайн, що відображає вашу індивідуальність та ідеї.
						<br/><br/>
Консультативний процес: Працюйте безпосередньо з нашими художниками, щоб вдосконалити ваше бачення.
						<br/><br/>
Різноманітні стилі: Від традиційних до сучасних, ми володіємо ними всіма.

					</Text>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				align-items="flex-start"
				overflow-x="hidden"
				overflow-y="hidden"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-justify-content="flex-start"
				margin="0px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				sm-flex-direction="column"
				sm-justify-content="center"
			>
				<Box
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 20% 0px"
					border-radius="24px"
					lg-width="auto"
					lg-margin="0px 32px 34px 0px"
					margin="0px 32px 0px 0px"
					min-width="188px"
					min-height="188px"
					sm-margin="0px 32px 24px 0px"
				>
					<Image
						src="https://luminiumrex.com/images/8.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top={0}
						left={0}
						right={0}
						bottom={0}
						min-height="100%"
					/>
				</Box>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="left"
					display="flex"
					align-items="flex-start"
					sm-text-align="left"
					lg-width="50%"
					sm-width="100%"
				>
					Художня трансформація: Надаємо старим татуюванням нове життя або приховуємо минулі помилки.
						<br/><br/>
Креативні рішення: Експертні поради щодо найкращого підходу до переробки фарб.
						<br/><br/>
Бездоганний результат: Бездоганна інтеграція з вашими існуючими татуюваннями.

				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://luminiumrex.com/images/9.jpg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://luminiumrex.com/images/0.jpg"
					/>
				</Box>
				<Text
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					md-text-align="left"
				>
					Яскраві кольори: Яскраві, стійкі чорнила, що витримують випробування часом.
						<br/><br/>
Відтінки та тони: Експертні техніки розтушовування для глибини та реалістичності.
						<br/><br/>
Орієнтовані на деталі: Бездоганне виконання як сміливих, так і складних малюнків.
				</Text>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://luminiumrex.com/images/11.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Завітайте до тату-салону Tattoo 044 і дозвольте нам створити татуювання, таке ж неординарне, як і ви. Зв'яжіться з нами, щоб записатися на консультацію або дізнатися більше про наші комплексні послуги тату. Ваша подорож до шедевру починається тут.
				</Text>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});